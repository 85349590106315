/* unplugin-vue-components disabled */import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "cart-wrapper"
};
const _hoisted_2 = {
  class: "cart-product-body"
};
const _hoisted_3 = {
  class: "cart-header"
};
const _hoisted_4 = {
  class: "d-flex justify-content-center"
};
const _hoisted_5 = {
  key: 0,
  class: "cart-total-items position-relative"
};
const _hoisted_6 = {
  class: "num-products"
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  version: "1.1",
  id: "Layer_1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 24 24",
  height: "35",
  width: "35",
  style: {
    "enable-background": "new 0 0 24 24"
  },
  "xml:space": "preserve"
};
const _hoisted_9 = {
  key: 0,
  class: "text-center"
};
const _hoisted_10 = {
  key: 1,
  class: "card-body-data"
};
const _hoisted_11 = {
  key: 0,
  class: "card-outer-data"
};
const _hoisted_12 = {
  class: "card-inner-data"
};
import { gsap } from "gsap";
import { onClickOutside } from "@vueuse/core";
import CartListCard from "@/components/cart/cartListCard.vue";
import CartListingPrice from "@/components/cart/cartListingPrice.vue";
import EmptyCart from "@/components/cart/emptyCart.vue";
import { isOpenCart } from "@/helpers/modal";
import { computed, toRaw } from "vue";
import { track } from "@/analytics/events";
import { createNamespacedHelpers } from "vuex-composition-helpers";
export default {
  __name: 'cartListing',
  emits: ["onclose"],
  setup(__props, {
    emit: __emit
  }) {
    const {
      useState,
      useActions
    } = createNamespacedHelpers("plans");
    const itiraStore = createNamespacedHelpers("itira");
    const userStore = createNamespacedHelpers("user");
    const {
      itira
    } = itiraStore.useState(["itira"]);
    const {
      user
    } = userStore.useState(["user"]);
    const {
      cart,
      statuscartListingloaing
    } = useState(["cart", "statuscartListingloaing"]);
    const {
      getAllCartsProducts
    } = useActions(["getAllCartsProducts"]);
    const closeModal = ref(null);
    const emit = __emit;
    onClickOutside(closeModal, () => {
      emit("onclose");
    });
    let filterCartListExpectCAPMoudle = computed(() => {
      return toRaw(cart.value).filter(({
        id
      }) => true);
      // return toRaw(cart.value).filter(({ id }) => id != "trip_side_assistance");
    });
    const tripDate = computed(() => {
      const cartRaw = toRaw(cart.value);
      if (!cartRaw) {
        return null;
      }
      return {
        departure_date: cartRaw[0].attributes.departure_date,
        arrival_date: cartRaw[0].attributes.arrival_date
      };
    });
    const loading = computed(() => {
      return statuscartListingloaing.value == "pending" ? true : false;
    });
    let enter = () => {
      let {
        itira_uuid
      } = toRaw(itira.value);
      getAllCartsProducts({
        itira_id: itira_uuid
      });
      gsap.to(".cart-wrapper", {
        opacity: 1,
        duration: 0.2
      });
      gsap.to(".cart-product-comp", {
        x: "0%",
        duration: 0.3,
        delay: 0.2
      });
    };
    let leave = () => {
      gsap.to(".cart-product-comp", {
        x: "100%",
        duration: 2
      });
      gsap.to(".cart-wrapper", {
        opacity: 0,
        duration: 3,
        delay: 0.5
      });
      console.log("leave");
    };
    if (toRaw(isOpenCart.value) === true) {
      track("Cart Viewed", {
        cart_id: itira.value.itira_uuid ?? '',
        products: cart.value ? Object.values(cart.value).map(value => {
          return value;
        }) : "Cart is empty"
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_Transition, {
        onEnter: _unref(enter),
        onLeave: _unref(leave),
        name: "cart"
      }, {
        default: _withCtx(() => [_unref(isOpenCart) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
          class: "cart-product-comp",
          ref_key: "closeModal",
          ref: closeModal
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_unref(filterCartListExpectCAPMoudle).length ? (_openBlock(), _createElementBlock("span", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("svg", {
          id: "Layer_1",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 24 21",
          height: "34.5",
          width: "34"
        }, [_createElementVNode("g", {
          id: "Group_2"
        }, [_createElementVNode("g", {
          id: "baggage"
        }, [_createElementVNode("path", {
          id: "Path_12",
          d: "M3.5,21c-1.93,0-3.5-1.57-3.5-3.5V7.5c0-1.93,1.57-3.5,3.5-3.5h4.5v-1.5c0-1.38,1.12-2.49,2.5-2.5h3c1.38,0,2.49,1.12,2.5,2.5v1.5h4.5c1.93,0,3.5,1.57,3.5,3.5v10c0,1.93-1.57,3.5-3.5,3.5H3.5Zm17-1c1.38,0,2.5-1.12,2.5-2.5V7.5c0-1.38-1.12-2.5-2.5-2.5h-1.5v15h1.5Zm-1.5,0V5h-2v15h2Zm-2,0V5H8v15h9Zm-9,0V5H4v15h4ZM3.5,5c-1.38,0-2.5,1.12-2.5,2.5v10c0,1.38,1.12,2.5,2.5,2.5h.5V5h-.5Zm11.5-1v-1.5c0-.83-.67-1.5-1.5-1.5h-3c-.83,0-1.49,.67-1.5,1.5v1.5h6Z"
        })])])], -1)), _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(filterCartListExpectCAPMoudle).length), 1)])) : (_openBlock(), _createElementBlock("span", _hoisted_7, [(_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[2] || (_cache[2] = [_createElementVNode("g", null, [_createElementVNode("title", null, "baggage"), _createElementVNode("path", {
          d: "M3.5,22.5C1.57,22.5,0,20.93,0,19V9c0-1.93,1.57-3.5,3.5-3.5H8V4c0.004-1.375,1.125-2.496,2.499-2.5H13.5\n                  c1.375,0.004,2.496,1.125,2.5,2.499V5.5h4.5C22.43,5.5,24,7.07,24,9v10c0,1.93-1.57,3.5-3.5,3.5H3.5z M20.5,21.5\n                  c1.378,0,2.5-1.122,2.5-2.5V9c0-1.379-1.122-2.5-2.5-2.5H20v15H20.5z M19,21.5v-15h-2v15H19z M16,21.5v-15H8v15H16z M7,21.5v-15H5\n                  v15H7z M3.5,6.5C2.121,6.5,1,7.621,1,9v10c0,1.378,1.121,2.5,2.5,2.5H4v-15H3.5z M15,5.5V4c-0.002-0.824-0.676-1.497-1.501-1.5\n                  h-2.998C9.676,2.503,9.003,3.176,9,4.001V5.5H15z"
        })], -1)])))]))]), _createElementVNode("button", {
          class: "close-btn btn subtitle-1",
          onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('onclose'))
        }, _cache[3] || (_cache[3] = [_createElementVNode("span", {
          class: "material-symbols-outlined"
        }, " close ", -1)]))]), loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [_createElementVNode("div", {
          class: "spinner-border",
          role: "status"
        }, null, -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_unref(filterCartListExpectCAPMoudle).length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filterCartListExpectCAPMoudle), cartData => {
          return _openBlock(), _createBlock(CartListCard, {
            key: cartData.id,
            cartData: cartData,
            tripDate: tripDate.value
          }, null, 8, ["cartData", "tripDate"]);
        }), 128))])])) : _createCommentVNode("", true), _unref(cart).length ? (_openBlock(), _createBlock(CartListingPrice, {
          key: 1
        })) : (_openBlock(), _createBlock(EmptyCart, {
          key: 2
        }))]))])], 512)])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["onEnter", "onLeave"]);
    };
  }
};